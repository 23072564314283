.Share {
  margin: 2em auto;
  text-align: center;
}

.Share-buttons {
  display: flex;
  justify-content: center;

  .SocialMediaShareButton {
    cursor: pointer;
  }

  svg {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 1em;
  }
}

.Share-cta {
  margin: .5em;
  text-transform: uppercase;
}