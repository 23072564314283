.Burger {
  border: 0;
  background-color: transparent;
  width: 30px;
  height: 20px;
  position: fixed;
  padding: 0;
  z-index: 2;
  right: 18px;
  top: 18px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.Burger-bar {
  width: 100%;
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
}