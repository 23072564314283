@import 'fonts.css';
@import '~scss/vars';
$heavy: 'Helvetica Neue LT W05 85 Heavy';

* {
  box-sizing: inherit;
}
body {
  box-sizing: border-box;
}

a {
  color: inherit;
}

body {
  font-family: $heavy;
  line-height: 1.5;
}

main {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: $navHeight;
  background-color: $bg-primary;
  color: $color-primary;
  min-height: 100vh;
}

.max {
  //max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.iframe-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.frame {
  width: 100%;
  //height: calc(100vh - #{$navHeight});
  position: relative;

  .frame-inner {
    padding: 1em;
  }

  @media (min-width: $frameBreak) {
    //max-width: 550px;
    //height: 80vh;
    margin-left: auto;
    margin-right: auto;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;

    // >div {
    //   border-radius: 5px;
    // }

    // .frame-bar:before {
    //   content: '';
    //   display: block;
    //   background-color: $black;
    //   height: 20px;
    //   border-top-left-radius: 5px;
    //   border-top-right-radius: 5px;
    //   position: sticky;
    //   top: $navHeight;
    //   left: 0;
    //   width: 100%;
    //   z-index: 1;
    // }

    .frame-inner {
      padding: 0 3em;
      //padding-top: 20px;
      max-width: 800px;
      margin: 0 auto;
    }

    &.Home .frame-bar:before {
      background-color: $purple;
    }
  }

  //@media (min-width: 900px) {
    //max-width: 800px;
  //}
}

.frame-inner {
  padding: 1em;
}

.quote-title {
  display: inline-block;
  background-color: $white;
  color: $bg-primary;
}

.Chat .Home-cta {
  padding: 5em 10px;
}

.VideoEmbed {
  border-radius: 15px;
  overflow: hidden;
}
.VideoEmbed-placeholder {
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.VideoEmbed-modal {
  align-items: center;
  background-color: $bg-primary;
  cursor: pointer;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 3;
  padding: 40px;

  @media (min-width: 800px) {
    padding: 30px 100px;
  }

  @media (min-width: 1200px) {
    padding: 30px 200px;
  }

  button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
  }

  iframe {
    animation: fade 3s forwards;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.iframe-height-wrapper {
  width: 100%;
  height: 100%;
}
.iframe-ratio-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin: auto;
  width: 100%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;

  }
}

.VideoEmbed-modal-close {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.VideoEmbed-modal-close-bar {
  background-color: white;
  width: 80%;
  height: 4px;
  position: absolute;
  left: 10%;
  top: 40%;

  &:first-child {
    transform: rotate(45deg);
  }
  &:last-child {
    transform: rotate(-45deg);
  }
}

.Frame-scroll {
  display: block;
  position: fixed;
  bottom: 25px;
  left: 50%;
  background-color: transparent;
  border: 0;
  width: 25px;
  height: 25px;
  transform: translateX(-50%);
  padding: 0;

  &:focus {
    transform: translateX(-50%) scale(1.1);
    outline: 0;
  }
}

.Frame-scroll-bar {
  bottom: 0;
  position: absolute;
  height: 4px;
  width: 100%;
  transform-origin: left center;

  &:first-child {
    left: calc(50% - 1px);
    transform: rotate(-45deg);
  }
  &:last-child {
    left: calc(50% + 1px);
    transform: rotate(-135deg);
  }
}

.logos {
  display: flex;
  width: 100%;
  figure {
    display: block;
    width: 100%;
    margin: 0;
  }
  img {
    display: block;
    width: 100%;
  }
}