@import "~scss/colors.scss";

.Nav {
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  position: fixed;
  width: 100%;
  min-height: 58px;
  top: 0;
  left: 0;
  padding: 8px;
  z-index: 1;

  svg {
    display: block;
    fill: inherit;
  }
}

.Nav-home {
  align-items: center;
  display: flex;
  position: fixed;
  top: 14px;
  left: 8px;
  z-index: 2;

  svg {
    display: block;
    height: 18px;
    width: auto;
  }
}

.Nav-home-local {
  margin-left: 1em;
  text-decoration: none;
  font-size: 18px;
}

.Nav-title {
  display: flex;
  justify-content: center;
}

.Nav-home-divider {
  margin-left: .5rem;
  &:after {
    display: block;
    content: '';
    height: 30px;
    border-left: 1px solid;
    margin-right: .5rem;
  }
}