@import '~scss/vars';


.Chat .frame-inner {
  padding: 20px;
}

.Message-inner,
.quote-title {
  position: relative;
  >svg {
    width: 25px;
    position: absolute;
    bottom: 0;
    left: -10px;

    * {
      fill: inherit;
    }
  }
}

.Message {
  max-width: 250px;
  margin: 20px 0;

  .Message-body a {
    word-break: break-all;
  }

  @media (min-width: 500px) {
    max-width: 300px;
  }

  @media (min-width: 900px) {
    max-width: 500px;
  }

  img {display: block; width: 100%;}
}

.Message-inner {
  display: inline-block;
}

.Message--user {
  margin-left: auto;
  .Message-inner>svg {
    right: -10px;
    left: auto;
    transform: rotateY(180deg)
  }
}

.Message-body,
.Message-sender,
.Message-link,
.quote-title {
  padding-left: 25px;
  padding-right: 25px;
}
.Message-body,
.Message-link,
.quote-title {
  border-radius: 30px;
  padding: 15px 25px;
}

.Message-link img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.Message-body {
  display: inline-block;
  font-size: 14pt;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.Message--sender .Message-inner > svg {
  fill: $bg-primary;
}
.Message--sender .Message-body {
  background-color: $bg-primary;
  color: $color-primary;
}

.Message--user .Message-inner {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.Message--user .Message-inner > svg {
  fill: $bg-secondary;
}
.Message--user .Message-body {
  background-color: $bg-secondary;
  color: $bg-primary;
}

.Message-sender {
  color: black;
  opacity: .6;
  margin-bottom: .25em;
}

.Message-link {
  display: inline-block;
  background-color: #5a5553;
  color: #fff;
  margin: 10px 0;
  text-decoration: none;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    filter: brightness(1.1);
  }
}

.Message-link-text {
  padding: 15px 30px 0;
}
